<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
    page() {
        return {
            title: this.title,
            meta: [{ name: "description" }],
        };
    },
    components: { Layout, PageHeader },
    data() {
        return {
            title: "",
            items: [],
            loading: false,
            messagelist: [],
            page: 1,
            userinfo: [],
            limit: 20,
            messagetotal: 0,
            activetab: "daily",
            monthdate: [],
            dailydate: [],
            pickerOptions: {
                shortcuts: [{
                    text: this.$t('statistics.sales.month.recent3'),
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setMonth(start.getMonth() - 2);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: this.$t('statistics.sales.month.recent6'),
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setMonth(start.getMonth() - 5);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: this.$t('statistics.sales.month.recent12'),
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setMonth(start.getMonth() - 11);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            pickerOptionsdaily: {
                shortcuts: [{
                    text: this.$t('statistics.sales.daily.recent3'),
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 2);
                        picker.$emit("pick", [start, end]);
                    }
                }, {
                    text: this.$t('statistics.sales.daily.recent7'),
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
                        picker.$emit("pick", [start, end]);
                    }
                }, {
                    text: this.$t('statistics.sales.daily.recent30'),
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setMonth(start.getMonth() -1);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            salesdata: []
        };
    },
    mounted() {
        this.title = this.$t("menuitems.setting.statistics");
        this.items = [
            {
                text: this.$t("dashboard.text"),
                href: "/",
            },
            {
                text: this.$t("menuitems.setting.statistics"),
                active: true,
            },
        ];
        this.userinfo = JSON.parse(localStorage.getItem("user"));
        //   this.page.title=this.$t('global.notice.type.notify')
    },
    methods: {
        switchdatatype() {
            this.salesdata = []
            if (this.activetab == 'all') this.onSubmit()
        },
        onSubmit() {
            var that = this
            that.salesdata = []
            that.loading = true
            let postdata = {}
            if (that.activetab == 'daily') {
                postdata = {
                    action: "querystatistics",
                    date: that.dailydate,
                    type: that.activetab,
                }
            } else if (that.activetab == 'month') {
                postdata = {
                    action: "querystatistics",
                    date: that.monthdate,
                    type: that.activetab,
                }
            } else {
                postdata = {
                    action: "querystatistics",
                    type: that.activetab,
                }
            }
            that.$axios
                .post(
                    that.apiuri,
                    postdata,
                    {
                        headers: { Openid: that.userinfo.openid },
                    }
                )
                .then(function (response) {
                    that.loading = false;
                    if (response.data.status == 200) {
                        that.salesdata = response.data.data;
                    } else {
                        that.$message.error(response.data.msg);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getmessagelist() {
            var that = this;
            that.loading = true;
            that.$axios
                .post(
                    that.apiuri,
                    {
                        action: "getnotifymessage",
                        page: that.page,
                        limit: that.limit,
                    },
                    {
                        headers: { Openid: that.userinfo.openid },
                    }
                )
                .then(function (response) {
                    that.loading = false;
                    that.messagelist = response.data.list;
                    that.messagetotal = response.data.count * 1;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getdailydate(e) {
            console.log(e)
        }
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body" v-loading="loading">
                        <el-tabs v-model="activetab" @tab-click="switchdatatype">
                            <el-tab-pane :label="$t('statistics.sales.daily.text')" name="daily">
                                <el-form ref="form" label-width="80px">
                                    <el-form-item :label="$t('statistics.sales.daily.v2')">
                                        <el-date-picker v-model="dailydate" type="daterange" align="right" unlink-panels
                                            :range-separator="$t('statistics.sales.month.to')"
                                            :start-placeholder="$t('statistics.sales.daily.start')"
                                            :end-placeholder="$t('statistics.sales.daily.end')"
                                            value-format="yyyy-MM-dd" format="yyyy-MM-dd"
                                            :picker-options="pickerOptionsdaily">
                                        </el-date-picker>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="primary" @click="onSubmit">{{ $t("statistics.sales.search")
                                            }}</el-button>
                                    </el-form-item>
                                </el-form>
                            </el-tab-pane>
                            <el-tab-pane :label="$t('statistics.sales.month.text')" name="month">
                                <el-form ref="form" label-width="80px">
                                    <el-form-item :label="$t('statistics.sales.month.v2')">
                                        <el-date-picker v-model="monthdate" type="monthrange" align="right"
                                            unlink-panels :range-separator="$t('statistics.sales.month.to')"
                                            :start-placeholder="$t('statistics.sales.month.start')"
                                            :end-placeholder="$t('statistics.sales.month.end')" value-format="yyyy-MM"
                                            :picker-options="pickerOptions">
                                        </el-date-picker>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="primary" @click="onSubmit">{{ $t("statistics.sales.search")
                                            }}</el-button>
                                    </el-form-item>
                                </el-form>
                            </el-tab-pane>
                            <el-tab-pane :label="$t('statistics.sales.all')" name="all">

                            </el-tab-pane>
                        </el-tabs>
                        <div class="row">
                            <div class="col-12">
                                <el-table :data="salesdata" style="width: 100%">
                                    <el-table-column prop="date" :label="$t('statistics.sales.date')"
                                        v-if="activetab == 'daily'">
                                    </el-table-column>
                                    <el-table-column prop="month" :label="$t('statistics.sales.month.text2')"
                                        v-else-if="activetab == 'month'">
                                    </el-table-column>
                                    <el-table-column prop="all" :label="$t('statistics.sales.all')" v-else>
                                    </el-table-column>
                                    <el-table-column prop="amount" :label="$t('statistics.sales.amount')">
                                    </el-table-column>
                                    <el-table-column prop="count" :label="$t('statistics.sales.count')">
                                    </el-table-column>
                                    <el-table-column prop="total" :label="$t('statistics.sales.total')">
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>